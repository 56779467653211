import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import axios from "axios";
import VueRouter from "vue-router";
import router from './router';


Vue.config.productionTip = false;
document
document.title = '杭州漉沙科技有限公司'
document.Description = '杭州漉沙科技有限公司，主要致力于AGV行业的服务商，主要业务为提供物流机器人、无线网络的整体解决方案、项目落地实施、项目的售后维护、机器视觉、服务器硬件提供等。'



axios.defaults.withCredentials = true;

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI, { size: "small" });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
