import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
 {
    path: '/',
    name: 'index',
    component: ()=>import('../components/Index.vue'),
   meta: {
     title: '杭州漉沙科技有限公司',
     description: '杭州漉沙科技有限公司，主要致力于AGV行业的服务商，主要业务为提供物流机器人、无线网络的整体解决方案、项目落地实施、项目的售后维护、机器视觉、服务器硬件提供等。'
   },
    children:[
      {
        path:'/Home',
        name:'home',
        // meta:{
        //   title:'首页'
        // },
        component:()=>import('../components/Home.vue'),
      },
      {
        path:'/Robot',
        name:'robot',
        // meta:{
        //   title:'产品技术'
        // },
        component:()=>import('../components/Robot.vue'),
        children: [
          {
            path:'/LatentRobot',
            name:'latentRobot',
            // meta:{
            //   title:'潜伏机器人'
            // },
            component:()=>import('../components/robot/LatentRobot.vue'),
          },
          {
            path:'/InsertionRobot',
            name:'insertionRobot',
            // meta:{
            //   title:'叉取机器人'
            // },
            component:()=>import('../components/robot/InsertionRobot.vue'),
          },
          {
            path:'/TankRobot',
            name:'TankRobot',
            // meta:{
            //   title:'料箱机器人'
            // },
            component:()=>import('../components/robot/TankRobot.vue'),
          },
          {
            path:'/RCS-2000',
            name:'rcs-2000',
            // meta:{
            //   title:'RCS-2000'
            // },
            component:()=>import('../components/robot/RCS-2000.vue'),
          },
          {
            path:'/IWMS',
            name:'iwms',
            // meta:{
            //   title:'IWMS'
            // },
            component:()=>import('../components/robot/IWMS.vue'),
          },
          {
            path:'/RCS-Lite-2',
            name:'rcs-Lite-2',
            // meta:{
            //   title:'RCS-Lite-2'
            // },
            component:()=>import('../components/robot/RCS-Lite-2.vue'),
          },
          {
            path:'/ChargingPile',
            name:'ChargingPile',
            // meta:{
            //   title:'全自动充电桩'
            // },
            component:()=>import('../components/robot/ChargingPile.vue'),
          },
          {
            path:'/Beeper',
            name:'Beeper',
            // meta:{
            //   title:'呼叫器'
            // },
            component:()=>import('../components/robot/Beeper.vue'),
          },
          {
            path:'/Controller',
            name:'Controller',
            // meta:{
            //   title:'控制器'
            // },
            component:()=>import('../components/robot/Controller.vue'),
          },
          {
            path:'/Hand',
            name:'Hand',
            // meta:{
            //   title:'手摇器'
            // },
            component:()=>import('../components/robot/Hand.vue'),
          },
          {
            path:'/OverallAnalysis',
            name:'OverallAnalysis',
            // meta:{
            //   title:'整体分析'
            // },
            component:()=>import('../components/robot/OverallAnalysis.vue'),
          },
          {
            path:'/OverallPlan',
            name:'OverallPlan',
            // meta:{
            //   title:'提供整体方案'
            // },
            component:()=>import('../components/robot/OverallPlan.vue'),
          },
          {
            path:'/CombingProcess',
            name:'CombingProcess',
            // meta:{
            //   title:'梳理流程和关键指标'
            // },
            component:()=>import('../components/robot/CombingProcess.vue'),
          },
          {
            path:'/CustomerRequirements',
            name:'CustomerRequirements',
            // meta:{
            //   title:'深入了解客户需求'
            // },
            component:()=>import('../components/robot/CustomerRequirements.vue'),
          },

          ]
      },
      {
        path:'/ServiceCenter',
        name:'ServiceCenter',
        // meta:{
        //   title:'客户案例'
        // },
        component:()=>import('../components/ServiceCenter.vue'),
      },
      {
        path:'/HongliSmartEnergy',
        name:'HongliSmartEnergy',
        // meta:{
        //   title:'客户案例'
        // },
        component:()=>import('../components/HongliSmartEnergy.vue'),
      },
      {
        path:'/Jinlong',
        name:'Jinlong',
        // meta:{
        //   title:'客户案例'
        // },
        component:()=>import('../components/Jinlong.vue'),
      },
      {
        path:'/Network',
        name:'Network',
        // meta:{
        //   title:'网络架构'
        // },
        component:()=>import('../components/Network.vue'),
        children: [
          {
            path:'/Router',
            name:'Router',
            // meta:{
            //   title:'路由器'
            // },
            component:()=>import('@/components/network/Router.vue'),
          },
          {
            path:'/Switch',
            name:'Switch',
            // meta:{
            //   title:'交换机'
            // },
              component:()=>import('@/components/network/Switch.vue'),
          },
          {
            path:'/LAN',
            name:'LAN',
            // meta:{
            //   title:'无线局域网'
            // },
            component:()=>import('@/components/network/LAN.vue'),
          },
        ]
    },
      {
        path: '/MachineVision',
        name: 'MachineVision',
        // meta: {
        //   title: '机器视觉'
        // },
        component: () => import('../components/MachineVision.vue'),
        children: [
          {
            path: '/Navigation',
            name: 'Navigation',
            // meta: {
            //   title: '工业相机导航页面'
            // },
            component: () => import('../components/machinevision/IndustrialNavigation.vue'),
            children: [
              {
                path: '/CSIndustrialCamera',
                name: 'CSIndustrialCamera',
                // meta: {
                //   title: 'CS系列工业面阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CSIndustrialCamera.vue'),
              },
              {
                path: '/CUIndustrialCamera',
                name: 'CUIndustrialCamera',
                // meta: {
                //   title: 'CU系列工业面阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CUIndustrialCamera.vue'),
              },
              {
                path: '/CHIndustrialCamera',
                name: 'CHIndustrialCamera',
                // meta: {
                //   title: 'CH系列工业面阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CHIndustrialCamera.vue'),
              },
              {
                path: '/CEIndustrialCamera',
                name: 'CEIndustrialCamera',
                // meta: {
                //   title: 'CE系列工业面阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CEIndustrialCamera.vue'),
              },
              {
                path: '/CAIndustrialCamera',
                name: 'CAIndustrialCamera',
                // meta: {
                //   title: 'CA系列工业面阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CAIndustrialCamera.vue'),
              },
              {
                path: '/CLIndustrialCamera',
                name: 'CLIndustrialCamera',
                // meta: {
                //   title: 'CL系列工业线阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CLIndustrialCamera.vue'),
              },
              {
                path: '/CBIndustrialCamera',
                name: 'CBIndustrialCamera',
                // meta: {
                //   title: 'CU系列扳级阵相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CBIndustrialCamera.vue'),
              },
              {
                path: '/CIIndustrialCamera',
                name: 'CIIndustrialCamera',
                // meta: {
                //   title: 'CU系列红外相机'
                // },
                component: () => import('@/components/machinevision/lndustrialnavigation/CIIndustrialCamera.vue'),
              },
            ]
          },
          {
            path: '/SmartNavigation',
            name: 'SmartNavigation',
            // meta: {
            //   title: '智能相机导航页面'
            // },
            component: () => import('../components/machinevision/SmartNavigation.vue'),
            children: [
              {
                path: '/SC1000SmartCamera',
                name: 'SC1000SmartCamera',
                // meta: {
                //   title: 'SC1000系列视觉传感器'
                // },
                component: () => import('../components/machinevision/smartnavigation/SC1000SmartCamera.vue'),
              },
              {
                path: '/SC2000ASmartCamera',
                name: 'SC2000ASmartCamera',
                // meta: {
                //   title: 'SC2000A系列导航传感器'
                // },
                component: () => import('../components/machinevision/smartnavigation/SC2000ASmartCamera.vue'),
              },
              {
                path: '/SC2000ESmartCamera',
                name: 'SC2000ESmartCamera',
                // meta: {
                //   title: 'SC2000E系列导航传感器'
                // },
                component: () => import('../components/machinevision/smartnavigation/SC2000ESmartCamera.vue'),
              },
              {
                path: '/SC3000SmartCamera',
                name: 'SC3000SmartCamera',
                // meta: {
                //   title: 'SC3000系列视觉传感器'
                // },
                component: () => import('../components/machinevision/smartnavigation/SC3000SmartCamera.vue'),
              },
              {
                path: '/SC6000SmartCamera',
                name: 'SC6000SmartCamera',
                // meta: {
                //   title: 'SC6000系列智能相机'
                // },
                component: () => import('../components/machinevision/smartnavigation/SC6000SmartCamera.vue'),
              },
              {
                path: '/X86SmartCamera',
                name: 'X86SmartCamera',
                // meta: {
                //   title: 'X86开放平台'
                // },
                component: () => import('../components/machinevision/smartnavigation/X86SmartCamera.vue'),
              },
            ]
          },
          {
            path: '/IntelligentCodeReaderNavigation',
            name: 'IntelligentCodeReaderNavigation',
            // meta: {
            //   title: '智能读码器导航页面'
            // },
            component: () => import('../components/machinevision/IntelligentCodeReaderNavigation.vue'),
            children: [
              {
                path: '/ID800IntelligentCodeReader',
                name: 'ID800IntelligentCodeReader',
                // meta: {
                //   title: 'ID800系列工业读码器'
                // },
                component: () => import('../components/machinevision/IntelligentCodeReader/ID800IntelligentCodeReader.vue'),
              },
              {
                path: '/ID2000SC2000ESmartCamera',
                name: 'ID2000SC2000ESmartCamera',
                // meta: {
                //   title: 'ID2000系列工业读码器'
                // },
                component: () => import('../components/machinevision/IntelligentCodeReader/ID2000SC2000ESmartCamera.vue'),
              },
              {
                path: '/ID3000SC2000ASmartCamera',
                name: 'ID3000SC2000ASmartCamera',
                // meta: {
                //   title: 'ID3000系列工业读码器'
                // },
                component: () => import('../components/machinevision/IntelligentCodeReader/ID3000SC2000ASmartCamera.vue'),
              },
              {
                path: '/ID5000SC3000SmartCamera',
                name: 'ID5000SC3000SmartCamera',
                // meta: {
                //   title: 'ID5000系列工业读码器'
                // },
                component: () => import('../components/machinevision/IntelligentCodeReader/ID5000SC3000SmartCamera.vue'),
              },
              {
                path: '/ID6000SC6000SmartCamera',
                name: 'ID6000SC6000SmartCamera',
                // meta: {
                //   title: 'ID6000系列物流读码器'
                // },
                component: () => import('../components/machinevision/IntelligentCodeReader/ID6000SC6000SmartCamera.vue'),
              },
              {
                path: '/ID7000X86SmartCamera',
                name: 'ID7000X86SmartCamera',
                // meta: {
                //   title: 'ID7000系列物流读码器'
                // },
                component: () => import('../components/machinevision/IntelligentCodeReader/ID7000X86SmartCamera.vue'),
              },
            ]
          },
          {
            path: '/StereoCameraNavigation',
            name: 'StereoCameraNavigation',
            // meta: {
            //   title: '立体相机导航页面'
            // },
            component: () => import('../components/machinevision/StereoCameraNavigation.vue'),
            children: [
              {
                path: '/3DStereoCamera',
                name: '3DStereoCamera',
                // meta: {
                //   title: '3D激光轮廓传感器'
                // },
                component: () => import('../components/machinevision/stereocamera/3DStereoCamera.vue'),
              },
              {
                path: '/GalvanometerStereoCamera',
                name: 'GalvanometerStereoCamera',
                // meta: {
                //   title: '激光振镜立体相机'
                // },
                component: () => import('../components/machinevision/stereocamera/GalvanometerStereoCamera.vue'),
              },
              {
                path: '/RGBDStereoCamera',
                name: 'RGBDStereoCamera',
                // meta: {
                //   title: 'RGB-D智能立体相机'
                // },
                component: () => import('../components/machinevision/stereocamera/RGBDStereoCamera.vue'),
              },
              {
                path: '/LaserStereoCamera',
                name: 'LaserStereoCamera',
                // meta: {
                //   title: '线激光立体相机'
                // },
                component: () => import('../components/machinevision/stereocamera/LaserStereoCamera.vue'),
              },
            ]
          },
          {
            path: '/StereoCameraNavigation',
            name: 'StereoCameraNavigation',
            // meta: {
            //   title: '视觉控制器导航页面'
            // },
            component: () => import('../components/machinevision/VisualController.vue'),
            children: [
              {
                path: '/VB2000VisualController',
                name: 'VB2000VisualController',
                // meta: {
                //   title: 'VB2000系列'
                // },
                component: () => import('../components/machinevision/visualcontroller/VB2000VisualController.vue'),
              },
              {
                path: '/VC2000VisualController',
                name: 'VC2000VisualController',
                // meta: {
                //   title: 'VC2000系列'
                // },
                component: () => import('../components/machinevision/visualcontroller/VC2000VisualController.vue'),
              },
              {
                path: '/VC3000VisualController',
                name: 'VC3000VisualController',
                // meta: {
                //   title: 'VC3000系列'
                // },
                component: () => import('../components/machinevision/visualcontroller/VC3000VisualController.vue'),
              },
              {
                path: '/SVC2000VisualController',
                name: 'SVC2000VisualController',
                // meta: {
                //   title: 'SVC2000系列'
                // },
                component: () => import('../components/machinevision/visualcontroller/SVC2000VisualController.vue'),
              },
              {
                path: '/SVA2000VisualController',
                name: 'SVA2000VisualController',
                // meta: {
                //   title: 'SVA2000系列'
                // },
                component: () => import('../components/machinevision/visualcontroller/SVA2000VisualController.vue'),
              },
            ]
          },
          {
            path: '/VisualComponents',
            name: 'VisualComponents',
            // meta: {
            //   title: '视觉组件导航页面'
            // },
            component: () => import('../components/machinevision/VisualComponents.vue'),
            children: [
              {
                path: '/HarvestCard',
                name: 'HarvestCard',
                // meta: {
                //   title: '采集卡'
                // },
                component: () => import('../components/machinevision/visualcomponents/HarvestCard.vue'),
              },

              {
                path: '/Cable',
                name: 'Cable',
                // meta: {
                //   title: '线缆'
                // },
                component: () => import('../components/machinevision/visualcomponents/Cable.vue'),
              },
              {
                path: '/PhotoelectricSensor',
                name: 'PhotoelectricSensor',
                // meta: {
                //   title: '光电传感器'
                // },
                component: () => import('../components/machinevision/visualcomponents/PhotoelectricSensor.vue'),
              },
            ]
          },
          {
            path: '/Lens',
            name: 'Lens',
            // meta: {
            //   title: '镜头导航页面'
            // },
            component: () => import('../components/machinevision/Lens.vue'),
            children: [
              {
                path: '/FALens',
                name: 'FALens',
                // meta: {
                //   title: 'FA镜头'
                // },
                component: () => import('../components/machinevision/lens/FALens.vue'),
              },
              {
                path: '/M12Lens',
                name: 'M12Lens',
                // meta: {
                //   title: 'M12镜头'
                // },
                component: () => import('../components/machinevision/lens/M12Lens.vue'),
              },
              {
                path: '/TelecentricLens',
                name: 'TelecentricLens',
                // meta: {
                //   title: '远心镜头'
                // },
                component: () => import('../components/machinevision/lens/TelecentricLens.vue'),
              },
              {
                path: '/LensAttachment',
                name: 'LensAttachment',
                // meta: {
                //   title: '镜头配件'
                // },
                component: () => import('../components/machinevision/lens/LensAttachment.vue'),
              },
              {
                path: '/LensSelectionTool',
                name: 'LensSelectionTool',
                // meta: {
                //   title: '镜头选型工具'
                // },
                component: () => import('../components/machinevision/lens/LensSelectionTool.vue'),
              },
            ]
          },
          {
            path: '/LightSource',
            name: 'LightSource',
            // meta: {
            //   title: '光源导航页面'
            // },
            component: () => import('../components/machinevision/LightSource.vue'),
            children: [
              {
                path: '/StyleLightSource',
                name: 'StyleLightSource',
                // meta: {
                //   title: '海康风格系列光源'
                // },
                component: () => import('../components/machinevision/lightsource/StyleLightSource.vue'),
              },
              {
                path: '/StyleLightSourceController',
                name: 'StyleLightSourceController',
                // meta: {
                //   title: '海康风格系列光源控制器'
                // },
                component: () => import('../components/machinevision/lightsource/StyleLightSourceController.vue'),
              },
              {
                path: '/StandardLightSource',
                name: 'StandardLightSource',
                // meta: {
                //   title: '标准系列光源'
                // },
                component: () => import('../components/machinevision/lightsource/StandardLightSource.vue'),
              },
              {
                path: '/StandardLightSourceController',
                name: 'StandardLightSourceController',
                // meta: {
                //   title: '标准系列光源控制器'
                // },
                component: () => import('../components/machinevision/lightsource/StandardLightSourceController.vue'),
              },
              {
                path: '/LightSourceAccessories',
                name: 'LightSourceAccessories',
                // meta: {
                //   title: '光源配件'
                // },
                component: () => import('../components/machinevision/lightsource/LightSourceAccessories.vue'),
              },
            ]
          },
          {
            path: '/MeasurementSystem',
            name: 'MeasurementSystem',
            // meta: {
            //   title: '测量系统导航页面'
            // },
            component: () => import('../components/machinevision/MeasurementSystem.vue'),
            children: [
              {
                path: '/MicroscopicSystem',
                name: 'MicroscopicSystem',
                // meta: {
                //   title: '显微系统'
                // },
                component: () => import('../components/machinevision/measurementsystem/MicroscopicSystem.vue'),
              },
            ]
          },
        ]
      },
      {
        path:'/ContactUs',
        name:'ContactUs',
        // meta:{
        //   title:'联系我们'
        // },
        component:()=>import('../components/ContactUs.vue'),
      },
      {
        path:'/PreSale',
        name:'PreSale',
        // meta:{
        //   title:'全栈售前服务'
        // },
        component:()=>import('../components/PreSale.vue'),
      },
      {
        path:'/ImplementationService',
        name:'ImplementationService',
        // meta:{
        //   title:'实施服务'
        // },
        component:()=>import('../components/ImplementationService.vue'),
      },
      {
        path:'/AfterSalesService',
        name:'AfterSalesService',
        // meta:{
        //   title:'售后服务'
        // },
        component:()=>import('../components/AfterSalesService.vue'),
      },
      {
        path:'/AboutLuSha',
        name:'AboutLuSha',
        // meta:{
        //   title:'关于漉沙'
        // },
        component:()=>import('../components/AboutLuSha.vue'),
      },
      {
        path:'/ChinaMap',
        name:'ChinaMap',
        // meta:{
        //   title:'关于漉沙'
        // },
        component:()=>import('../components/ChinaMap.vue'),
      },
      {
        path:'/StaffWorld',
        name:'StaffWorld',
        // meta:{
        //   title:'员工天地'
        // },
        component:()=>import('../components/StaffWorld.vue'),
      },{
        path:'/CaseCenter',
        name:'CaseCenter',
        // meta:{
        //   title:'案例中心'
        // },
        component:()=>import('../components/CaseCenter.vue'),
      },

    ]
  }



]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      // 获取导航栏的高度
      const navbarHeight = document.querySelector('.aside-container').offsetHeight;
      const element = document.querySelector(to.hash);

      if (element) {
        // 计算需要滚动到的位置
        const scrollPosition = element.offsetTop - navbarHeight;
        // 滚动到指定位置
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }
})

router.afterEach((to, from) => {

  window.scrollTo(0,0);
  // 等待 Vue 完成 DOM 更新
  Vue.nextTick(() => {
    if (to.hash) {
      // 获取导航栏的高度
      const navbarHeight = document.querySelector('.aside-container').offsetHeight;
      const element = document.querySelector(to.hash);

      if (element) {
        // 计算需要滚动到的位置
        const scrollPosition = element.offsetTop - navbarHeight;
        // 滚动到指定位置
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  });
});
export default router
